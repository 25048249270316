<template>
  <div class="space-basic">
    <b-card>
      <h2>決済</h2>
      <div class="mb-3">
        こちらの内容でお間違えなければ、｢今すぐ購入｣ボタンを押して下さい。
      </div>
      <hr>
      <div
        v-if="dataList.length === 0"
        class="mt-2"
      >
        カートには何も入っていません。
      </div>
      <div
        v-else
        class=""
      >
        <div class="mt-2 mb-2">
          <div
            v-for="(item, index) in dataList"
            :key="index"
            class=""
          >
            <div class="d-flex justify-content-between mb-2">
              <div class="text-mid">
                {{ item.lessonName }}
              </div>
              <div class="d-flex text-mid">
                {{ (item.price / 1.1).toLocaleString() }} 円
              </div>
            </div>
          </div>
          <div
            v-if="discount > 0"
            class=""
          >
            <div class="d-flex justify-content-between mb-2">
              <div class="text-mid">
                セット割引
              </div>
              <div class="d-flex">
                <div class="text-mid text-danger">
                  - {{ discount.toLocaleString() }} 円
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="text-right text-mid mb-1 mt-2">
          {{ total.toLocaleString() }} 円 (税抜)
        </div>
        <div class="text-right">
          <span class="text-large text-primary">{{ (total * 1.1 ).toLocaleString() }}</span> 円 (税込)
        </div>
        <div
          v-if="total > 0"
          class="btnSpace text-center mt-3"
        >
          <!-- eslint-disable -->
          <div>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick">
              <input type="hidden" name="hosted_button_id" value="GKWLKADT9YESS">
              <input type="image" src="https://www.paypalobjects.com/ja_JP/JP/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - オンラインでより安全・簡単にお支払い">
              <img alt="" border="0" src="https://www.paypalobjects.com/ja_JP/i/scr/pixel.gif" width="1" height="1">
            </form>
          </div>
          <!-- eslint-enable -->
          <b-button
            variant="text-primary"
            block
            class="mt-2"
            to="/cart"
          >
            <span>カートに戻る</span>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BButton,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'
import useCart from '@/components/js/compositionAPI/cart/useCart'

export default {
  components: {
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const {
      dataList,
      total,
      discount,
      getSum,
      deleteItem,
      pay,
      confirm,
    } = useCart()

    onMounted(() => {
      getSum()
    })

    return {
      dataList,
      total,
      discount,
      getSum,
      deleteItem,
      pay,
      confirm,
    }
  },
}
</script>

<style scoped>
.videoSpace {
  width: 100%;
  margin: 0 auto 0 auto;
}
.btnSpace {
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .btnSpace {
    width: 40%;
  }
}
</style>
