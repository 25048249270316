<template>
  <CartPay />
</template>

<script>
import CartPay from '@/components/basic/cart/CartPay.vue'

export default {
  components: {
    CartPay,
  },
}
</script>
